#in-page-player {

  [hidden] {
    display: none;
  }

  *,
  ::before,
  ::after {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
  }

  .playback-toggler {
    border: 1px solid #f8f8f8;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .right-4 {
    right: 1rem;
  }

  .top-4 {
    top: 1rem;
  }

  .top-14 {
    top: 3.5rem;
  }

  .left-0 {
    left: 0px;
  }

  .right-0 {
    right: 0px;
  }

  .mr-6 {
    margin-right: 1.5rem;
  }

  .mb-3 {
    margin-bottom: 0.75rem;
  }

  .ml-\[10px\] {
    margin-left: 10px;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-3 {
    margin-left: 0.75rem;
  }

  .ml-2 {
    margin-left: 0.5rem;
  }

  .mt-5 {
    margin-top: 1.25rem;
  }

  .mt-10 {
    margin-top: 2.5rem;
  }

  .ml-5 {
    margin-left: 1.25rem;
  }

  .block {
    display: block;
  }

  .flex {
    display: flex;
  }

  .hidden {
    display: none;
  }

  .h-6 {
    height: 1.5rem;
  }

  .h-screen {
    height: 100vh;
  }

  .h-24 {
    height: 6rem;
  }

  .h-control-panel {
    height: 4.5rem;
  }

  .h-72 {
    height: 18rem;
  }

  .w-6 {
    width: 1.5rem;
  }

  .w-screen {
    width: 100vw;
  }

  .w-player {
    width: 640px;
  }

  .w-24 {
    width: 6rem;
  }

  .max-w-lg {
    max-width: 32rem;
  }


  .cursor-pointer {
    cursor: pointer;
  }

  .flex-col {
    flex-direction: column;
  }

  .items-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .overflow-visible {
    overflow: visible;
  }

  .rounded-xl {
    border-radius: 0.75rem;
  }

  .rounded-md {
    border-radius: 0.375rem;
  }

  .rounded-full {
    border-radius: 9999px;
  }

  .rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }

  .border {
    border-width: 1px;
  }

  .border-t {
    border-top-width: 1px;
  }

  .border-player-light-border {
    border-color: rgba(15, 23, 42, 0.06);
  }

  .border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }

  .border-play-pause-light-border {
    border-color: rgba(15, 23, 42, 0.06);
  }

  .bg-highlight {
    background-color: rgba(56, 189, 248, 0.17);
  }

  .bg-sky-400\/10 {
    background-color: rgb(56 189 248 / 0.1);
  }

  .bg-emerald-400\/10 {
    background-color: rgb(52 211 153 / 0.1);
  }

  .fill-slate-500 {
    fill: #64748b;
  }

  .stroke-white {
    stroke: #fff;
  }

  .stroke-\[\#0A1122\] {
    stroke: #0A1122;
  }

  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .pt-10 {
    padding-top: 2.5rem;
  }

  .pb-4 {
    padding-bottom: 1rem;
  }

  .pb-6 {
    padding-bottom: 1.5rem;
  }

  .text-center {
    text-align: center;
  }

  .font-sans {
    font-family: Inter var, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .font-medium {
    font-weight: 500;
  }

  .font-semibold {
    font-weight: 600;
  }

  .leading-7 {
    line-height: 1.75rem;
  }

  .leading-6 {
    line-height: 1.5rem;
  }

  .leading-5 {
    line-height: 1.25rem;
  }

  .tracking-wide {
    letter-spacing: 0.025em;
  }

  .text-slate-900 {
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity));
  }

  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }

  .text-sky-500 {
    --tw-text-opacity: 1;
    color: rgb(14 165 233 / var(--tw-text-opacity));
  }

  .text-sky-600 {
    --tw-text-opacity: 1;
    color: rgb(2 132 199 / var(--tw-text-opacity));
  }

  .text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
  }

  .text-emerald-600 {
    --tw-text-opacity: 1;
    color: rgb(5 150 105 / var(--tw-text-opacity));
  }

  .text-emerald-300 {
    --tw-text-opacity: 1;
    color: rgb(110 231 183 / var(--tw-text-opacity));
  }

  .shadow-player-light {
    --tw-shadow: 0px 15px 25px rgba(15, 23, 42, 0.1), 0px 5px 10px rgba(15, 23, 42, 0.04);
    --tw-shadow-colored: 0px 15px 25px var(--tw-shadow-color), 0px 5px 10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .blur-2xl {
    --tw-blur: blur(40px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  /*
Massive help from: https://nikitahl.com/style-range-input-css
*/

  input[type="range"] {
    -webkit-appearance: none;
    height: 8px;
    background: #F1F5F9;
    border-radius: 5px;
    background-image: linear-gradient(#38BDF8, #38BDF8);
    background-size: 0% 100%;
    background-repeat: no-repeat;
  }

  html.dark input[type="range"] {
    background: #0F172A;
    background-image: linear-gradient(#38BDF8, #38BDF8);
    background-size: 0% 100%;
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.06);
    background-repeat: no-repeat;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #FFFFFF;
    cursor: ew-resize;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
  }

  html.dark input[type="range"]::-webkit-slider-thumb,
  html.dark input[type="range"]::-moz-range-thumb,
  html.dark input[type="range"]::-ms-thumb {
    background: #E2E8F0;
  }

  input[type="range"]::-webkit-slider-runnable-track,
  input[type="range"]::-moz-range-track,
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  div#song-saved.saved svg path {
    fill: #38BDF8;
    stroke: #38BDF8;
  }

  div.amplitude-play-pause.amplitude-playing #pause-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-paused #pause-icon {
    display: none;
  }

  div.amplitude-play-pause.amplitude-paused #play-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-playing #play-icon {
    display: none;
  }

  div.amplitude-shuffle.amplitude-shuffle-on svg path {
    fill: #38BDF8;
  }

  div.amplitude-repeat-song.amplitude-repeat-song-on svg path {
    fill: #38BDF8;
  }

  .hover\:bg-sky-400\/20:hover {
    background-color: rgb(56 189 248 / 0.2);
  }

  .hover\:bg-emerald-400\/20:hover {
    background-color: rgb(52 211 153 / 0.2);
  }

  .dark .dark\:block {
    display: block;
  }

  .dark .dark\:hidden {
    display: none;
  }

  .dark .dark\:border-player-dark-border {
    border-color: rgba(255, 255, 255, 0.16);
  }

  .dark .dark\:border-cover-dark-border {
    border-color: rgba(255, 255, 255, 0.1);
    ;
  }

  .dark .dark\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgb(17 24 39 / var(--tw-border-opacity));
  }

  .dark .dark\:border-play-pause-dark-border {
    border-color: rgba(255, 255, 255, 0.06);
  }

  .dark .dark\:bg-\[\#0A1122\] {
    --tw-bg-opacity: 1;
    background-color: rgb(10 17 34 / var(--tw-bg-opacity));
  }

  .dark .dark\:bg-player-dark-background {
    background-color: rgba(30, 41, 59, 0.7);
  }

  .dark .dark\:bg-control-panel-dark-background {
    background-color: rgba(23, 31, 50, 0.5);
    ;
  }

  .dark .dark\:bg-play-pause-dark-background {
    --tw-bg-opacity: 1;
    background-color: rgb(47 59 79 / var(--tw-bg-opacity));
  }

  .dark .dark\:fill-slate-400 {
    fill: #94a3b8;
  }

  .dark .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark .dark\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-300 {
    --tw-text-opacity: 1;
    color: rgb(125 211 252 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-400 {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
  }

  .dark .dark\:text-sky-400\/70 {
    color: rgb(56 189 248 / 0.7);
  }

  .dark .dark\:text-emerald-400 {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
  }

  .dark .dark\:shadow-player-dark {
    --tw-shadow: inset 0px 2px 4px rgba(255, 255, 255, 0.06);
    ;
    --tw-shadow-colored: inset 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .dark .dark\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  }

  @media (min-width: 1280px) {
    .xl\:flex {
      display: flex;
    }
  }
}