.pdf-bg {
  background-color: #f3f3f3;

  div.amplitude-play-pause.amplitude-playing #pause-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-paused #pause-icon {
    display: none;
  }

  div.amplitude-play-pause.amplitude-paused #play-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-playing #play-icon {
    display: none;
  }
}