.amplitude-player-waveform {
  svg:not(#play-icon):not(#pause-icon) {
    height: 35px;
    width: 100%;
    transform: scaley(2.5);
    margin-top: 5px;
  }

  svg g path {
    stroke: #2d5e7e;
    stroke-width: 0.5px
  }

  div.amplitude-play-pause.amplitude-playing #pause-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-paused #pause-icon {
    display: none;
  }

  div.amplitude-play-pause.amplitude-paused #play-icon {
    display: block;
  }

  div.amplitude-play-pause.amplitude-playing #play-icon {
    display: none;
  }

  input[type="range"]#song-played-progress-0 {
    width: 100%;
    margin-top: -34px;
    -webkit-appearance: none;
    z-index: 9999;
    background-color: inherit;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  input[type="range"]#song-played-progress-0:focus {
    outline: none;
  }

  input[type="range"]#song-played-progress-0::-webkit-slider-runnable-track {
    width: 100%;
    cursor: pointer;
    border-radius: 0px;
    height: 35px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-appearance: none;
  }

  input[type="range"]#song-played-progress-0::-webkit-slider-thumb {
    width: 2px;
    height: 30px;
    background-color: rgb(64, 77, 86);
    border-radius: 20px;
    -webkit-appearance: none;
  }

  input[type="range"]#song-played-progress-0::-moz-range-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    border-radius: 0px;
    height: 68px;
    background-color: rgba(0, 0, 0, 0);
  }

  input[type="range"]#song-played-progress-0::-moz-range-thumb {
    width: 5px;
    height: 68px;
    background-color: white;
    border-radius: 20px;
    -webkit-appearance: none;
    margin-top: -34px;
  }

  input[type="range"]#song-played-progress-0::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.25);
  }

  input[type="range"]#song-played-progress-0::-ms-fill-lower {
    background: transparent;
  }

  input[type="range"]#song-played-progress-0::-ms-fill-upper {
    background: transparent;
  }

  input[type="range"]#song-played-progress-0::-ms-thumb {
    width: 28px;
    height: 28px;
    background-color: white;
    border-radius: 20px;
    -webkit-appearance: none;
    margin-top: -10px;
  }
}

.amplitude-player-waveform.dark {
  svg g path {
    stroke: #a3c7df;
    stroke-width: 0.5px
  }

  input[type="range"]#song-played-progress-0::-ms-thumb {
    background-color: #318fce;
  }

  input[type="range"]#song-played-progress-0::-webkit-slider-runnable-track {
    background-color: rgba(231, 231, 231, 0);
  }

  input[type="range"]#song-played-progress-0::-webkit-slider-thumb {
    background-color: rgb(159, 191, 215);
  }

  input[type="range"]#song-played-progress-0::-moz-range-track {
    background: rgba(254, 254, 254, 0.97);
  }

  input[type="range"]#song-played-progress-0::-moz-range-thumb {
    background-color: white;
  }

  input[type="range"]#song-played-progress-0::-ms-track {
    background: rgba(254, 254, 254, 0.97);
  }
}