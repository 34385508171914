.transcript {
  font-size: 1.2em;
  line-height: 1.6;
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  overflow-wrap: break-word;
}

.highlight {
  background-color: #ffcc00;
  font-weight: bold;
}

.light-highlight {
  background-color: #ffffaa;
}